import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'semantic-ui-css/semantic.min.css'
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CacheProvider } from '@rest-hooks/react';
import configs from "./config/config";
import {UserProvider} from "./utils/hooks/UserProvider";
import {ChakraProvider} from '@chakra-ui/react'
import ReportProvider from "./utils/contexts/ReportContext";
import {FacebookProvider} from "react-facebook";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import TagManager from "react-gtm-module";
import {CookieConsent} from "react-cookie-consent";

const tagManagerArgs = {
    gtmId: 'G-CWNV7YSDKN'
}

if (process.env.NODE_ENV === "production") {
    TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <CacheProvider>
      <ChakraProvider>
          <GoogleOAuthProvider clientId={configs.google.oauth.clientId}>
              <FacebookProvider appId="906460354130177">
                  <UserProvider>
                      <BrowserRouter>
                        <App />
                      </BrowserRouter>
                      <CookieConsent buttonText="Am înțeles">Acest site folosește cookie-uri. Continuarea navigării reprezintă acceptul dvs. pentru această folosință. Pentru mai multe detalii privind gestionarea preferințelor privind cookie-uri, vedeți <a href="/cookies">Politica de utillizare cookie-uri</a></CookieConsent>
                  </UserProvider>
              </FacebookProvider>
          </GoogleOAuthProvider>
      </ChakraProvider>
  </CacheProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
