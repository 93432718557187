import {Box, Heading} from "@chakra-ui/react";

export default function Stats()
{
    return (
        <Box boxShadow="md" bg="gray.100" rounded={4} p={4}>
            <Heading>Aceasta pagina este in constructie.</Heading>
            <Heading size="sm">Revenim in scurt timp cu statistici interesate legate de problemele noastre.</Heading>
        </Box>
    )
}