import {createResource, Entity, RestEndpoint} from '@rest-hooks/rest';
import configs from '../config/config';
import {User} from "./User";

export class Report extends Entity {
    id = 0;
    lat = 0;
    long = 0;
    rid = 0;
    createdAt:string = '';

    pk() {
        return `${this.id}`;
    }

    static schema = {
        PaginatedReports: {
            rows: Report,

        },
    };
}

// @ts-ignore
export const ReportResource = new createResource({
    urlPrefix: configs.apiUrl,
    path: '/report/:id',
    schema: Report,
});

export const getReports = ReportResource.getList.extend({
    schema: { rows: [Report], currentPage: 1 },
});

export const getNextPage = getReports.paginated('cursor');

export const getPreview = new RestEndpoint({
    urlPrefix: configs.apiUrl,
    path: '/report/preview',
    method: 'POST',
    schema: Report,
});