import React, {useEffect, useState} from 'react';
import '../App.css';
import {GoogleLogin} from "@react-oauth/google";
import {loginWithFacebook, loginWithGoogle, User, UserResource} from "../api/User";
import {useUser} from "../utils/hooks/UserProvider";
import LogoAsociatie from '../assets/logo-gal-berceni.png';
import { EmailIcon } from '@chakra-ui/icons'
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel, Alert, AlertIcon,
    Avatar,
    Box,
    Button,
    ButtonGroup,
    Center,
    Container, Divider,
    Flex,
    Heading,
    Highlight, Icon, IconButton,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Text,
    useDisclosure, useToast

} from "@chakra-ui/react";
import {completeUserDetails, convertTimeToLocalTime} from "../utils/general";
import UserDetails from "../UserDetails";
import Logo from "../assets/logo_.png";
import ReportComponent from "../ReportComponent";
import ReportMap from "../ReportMap";
import useRelativeTimeFormat from "../utils/hooks/useRelativeTimeFormat";
import {useReport} from "../utils/contexts/ReportContext";
import {useLogin} from "react-facebook";
import {FaFacebook} from 'react-icons/fa';
import configs from "../config/config";
import {News, newsList} from "../api/News";
import {Wind, windList} from "../api/Wind";
import {Link} from "react-router-dom";
import {Formik, FormikProps} from "formik";

function Home() {

    const {
        setToken,
        setIsAuthenticated,
        isAuthenticated,
        userData,
        setUserData,
        showEditForm,
        setShowEditForm,
        lastReportHours,
        loginToast,
        activeIndex,
        setActiveIndex,
        openEmailLogin
    } = useUser();
    const intl = useRelativeTimeFormat();
    const {lastReports, setLastReportsInterval, lastReportsInterval} = useReport();
    const {login} = useLogin();

    const [news, setNews] = useState([]);
    const [wind, setWind] = useState<Wind | null>(null);
    const toast = useToast();

    useEffect(() => {
        newsList({exclude: 2})
            .then(data => setNews(data))
            .catch(() => {
            });

        windList()
            .then(w => setWind(w))
            .catch(() => {
            });
    }, [0]);

    const {isOpen: aboutUsOpen, onOpen: openAboutUs, onClose: closeAboutUs} = useDisclosure();

    return (
        <>
            {configs.general.testMode && (
                <Alert status='warning' mb={4}>
                    <AlertIcon/>
                    Aplicatia este in teste in aceasta perioada, deci NU trimite sesizarile organelor abilitate.
                    Trimite un singur exemplar (unul pentru GNM si unul pentru DSP) catre adresa voastra de email.
                    Va incurajam sa testati aplicatia si daca observati nereguli sa ne scrieti la adresa
                    iarmiroase@gmail.com
                </Alert>
            )}
            <SimpleGrid columns={{base: 1, md: 2}} spacing={10} alignItems="flex-start">
                <Box>
                    <Box boxShadow="base" bg="gray.50" rounded={4}>
                        <Accordion defaultIndex={isAuthenticated ? [0, 1, 2] : [0]} index={activeIndex}
                                   onChange={(index: number[]) => setActiveIndex(index)} allowMultiple>
                            <AccordionItem>
                                <AccordionButton>
                                    <Heading size="lg" flex='1' textAlign='left'>
                                        1. Contul meu
                                    </Heading>
                                    <AccordionIcon/>
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    {isAuthenticated && userData ? (
                                        <Flex gap={4}>
                                            <Avatar name={userData.full_name} src={userData.avatar}/>
                                            <Box>
                                                <Text>Hei, {userData.full_name}</Text>
                                                {!!lastReportHours && <Text fontSize="xs">Ai facut in
                                                    total {userData.Reports.length} sesizari. Ultima sesizare
                                                    trimisa {intl.format(-parseInt(String(lastReportHours)), 'hours')}</Text>}
                                                <Button colorScheme="gray" mt={2} size="xs"
                                                        onClick={() => setToken(null)}>Deconectare</Button>
                                            </Box>
                                        </Flex>
                                    ) : (
                                        <>
                                            <Text>
                                                <Highlight
                                                    query={['iar miroase']}
                                                    styles={{px: '2', py: '1', rounded: 'full', bg: 'red.100'}}
                                                >
                                                    Salut! Probabil iar miroase in zona ta. Suntem aici sa te ajutam
                                                    sa
                                                    trimiti sesizari catre organele abilitate de cate ori este
                                                    nevoie.
                                                    Si rapid!
                                                </Highlight>

                                                    Aplicatia se refera la mirosul de hidrogen sulfurat, un gaz foarte toxic, prezent (si confirmat de autoritati) in toata tara si mai ales in jurul gropilor de gunoi.
                                            </Text>
                                            <Flex gap={4} mt={4} flexWrap="wrap" flexDirection={{base: "column", md: "row"}}>
                                                <GoogleLogin
                                                    width={40}
                                                    onSuccess={credentialResponse => {
                                                        loginWithGoogle({token: credentialResponse.credential}).then((data: User) => {
                                                            setToken(data.access_token)
                                                            setIsAuthenticated(true);
                                                            setUserData(data);
                                                        }).then(() => loginToast());
                                                        ;
                                                    }}
                                                    onError={() => {
                                                        console.log('Login Failed');
                                                    }}
                                                />
                                                <Button
                                                    w={{base: '100%', md: '45%'}}
                                                    maxW={'100%'}
                                                    colorScheme={'facebook'}
                                                    rounded={5}
                                                    onClick={async () => {
                                                        try {
                                                            const response = await login({
                                                                scope: 'email',
                                                            });

                                                            console.log(response);

                                                            loginWithFacebook({token: response.authResponse.accessToken}).then((data: User) => {
                                                                setToken(data.access_token)
                                                                setIsAuthenticated(true);
                                                                setUserData(data);
                                                            }).then(() => loginToast());
                                                        } catch (error: any) {
                                                            console.log(error.message);
                                                        }
                                                    }}
                                                    leftIcon={<FaFacebook/>}>
                                                    <Center>
                                                        <Text fontSize="sm">Logare cu Facebook</Text>
                                                    </Center>
                                                </Button>
                                                <Button onClick={openEmailLogin} w={"100%"}><EmailIcon mr={2} />Conectare cu adresa de email</Button>

                                                <Alert status='info'>
                                                    <AlertIcon />
                                                    <Text fontSize="xs">Prin inregistrare / conectare confirm faptul ca am citit <Link to="/confidentialitate">Politica de confidentialitate</Link>, cat si <Link to="/cookies">Politia de fisiere Cookies</Link>, le inteleg si le accept pe amandoua</Text>
                                                </Alert>
                                            </Flex>
                                        </>
                                    )}
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem isDisabled={!isAuthenticated} isFocusable={!isAuthenticated}>
                                <AccordionButton>
                                    <Heading size="lg" flex='1' textAlign='left'>
                                        2. Datele mele
                                    </Heading>
                                    <AccordionIcon/>
                                </AccordionButton>
                                {isAuthenticated && (
                                    <AccordionPanel pb={4}>
                                        {showEditForm && userData ?
                                            <Formik
                                                onSubmit={(values) => {
                                                    values.access_token = userData.access_token;
                                                    UserResource.update({id: userData.id}, values).then((user: User) => {
                                                        setUserData(user);
                                                        toast({
                                                            title: 'Date actualizate',
                                                            description: "Ai toate datele salvate, asa ca poti trimite o sesizare noua.",
                                                            status: 'success',
                                                            duration: 9000,
                                                            isClosable: true,
                                                        })
                                                    });
                                                }}
                                                initialValues={userData}
                                            >
                                                {(formik: FormikProps<any>) => {
                                                    return (
                                                        <UserDetails userData={userData}/>
                                                    )}}
                                            </Formik>
                                                : (
                                            <>
                                                <Text mb={4}>Vad ca ai salvat toate datele necesare. Apasa butonul
                                                    de
                                                    mai jos daca vrei sa le modifici. Daca totul este ok, poti merge
                                                    mai
                                                    departe</Text>
                                                <Button size="xs" colorScheme="purple"
                                                        onClick={() => setShowEditForm(true)}>Editeaza
                                                    informatiile</Button>
                                            </>
                                        )}
                                    </AccordionPanel>
                                )}
                            </AccordionItem>
                            <AccordionItem isDisabled={!isAuthenticated}>
                                <AccordionButton>
                                    <Heading size="lg" flex='1' textAlign='left'>
                                        3. Sesizare
                                    </Heading>
                                    <AccordionIcon/>
                                </AccordionButton>
                                {isAuthenticated && (
                                    <AccordionPanel pb={4}>
                                        {!completeUserDetails(userData) ? (
                                            <>
                                                <Text>Stiu ca esti suparat pe faptul ca miroase in zona ta, dar
                                                    inainte
                                                    de toate avem nevoie de cateva informatii de contact.
                                                    Completeaza
                                                    toate datele necesare in formularul de mai sus si apasa butonul
                                                    "Salveaza"</Text>
                                            </>
                                        ) : (
                                            <ReportComponent/>
                                        )}
                                    </AccordionPanel>
                                )}
                            </AccordionItem>
                        </Accordion>
                    </Box>
                    {!!news && news.map((item: News, index) => (
                        <Alert mt={4} status={item.news_type === 1 ? 'info' : item.news_type === 2 ? 'error' : 'warning'}>
                            <AlertIcon/>
                            <>{item.status}</>
                            <div dangerouslySetInnerHTML={{ __html: item.body }} />
                        </Alert>
                    ))}
                </Box>
                <Box>
                    {!!lastReports && (
                        <Box boxShadow="base" bg="gray.50" rounded={4}>
                            <Heading size="xs" p={4}>Sesizari in ultimele <ButtonGroup size='sm' isAttached
                                                                                       variant='outline'>
                                <Button isActive={lastReportsInterval === 1} onClick={() => setLastReportsInterval(1)}>O
                                    ora</Button>
                                <Button isActive={lastReportsInterval === 24}
                                        onClick={() => setLastReportsInterval(24)}>24
                                    de ore</Button>
                                <Button isActive={lastReportsInterval === 72}
                                        onClick={() => setLastReportsInterval(72)}>3
                                    zile</Button>
                                <Button isActive={lastReportsInterval === 720}
                                        onClick={() => setLastReportsInterval(720)}>30
                                    zile</Button>
                            </ButtonGroup> ({lastReports.length})</Heading>
                            <ReportMap long={isAuthenticated && userData ? userData.City?.longitude : undefined} lat={isAuthenticated && userData ? userData.City?.latitude : undefined}/>
                        </Box>
                    )}
                </Box>
            </SimpleGrid>
        </>
    );
}

export default Home;
