const configs = {
    apiUrl: "https://www.iarmiroase.ro/api",
    google: {
        oauth: {
            clientId: '261678008472-mbss24f5a9k5fgt3bv59ltvv36mtkrq7.apps.googleusercontent.com',
            secret: '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',
        },
        mapsApiKey: 'AIzaSyCzURSgxFPEPjwaETLTj1_nLTWsnTVICMU',
    },
    general: {
        reportDelayHours: 24,
        testMode: false,
    }
};

const dev = {
    general: {
        reportDelayHours: 0,
        testMode: true
    },
    apiUrl: "https://staging.iarmiroase.ro/api"
};

const local = {
    general: {
        reportDelayHours: 0,
        testMode: true
    },
    apiUrl: "http://localhost:3001/api"
};

const prod = {};
console.log(process.env);
let envConfig;
if (process.env.REACT_APP_MODE === "development") {
    envConfig = dev;
} else if (process.env.REACT_APP_MODE === "local") {
    envConfig = local;
} else {
    envConfig = prod;
}

const newConf = {...configs, ...envConfig}

export default newConf;