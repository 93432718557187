import {createResource, Entity, RestEndpoint} from '@rest-hooks/rest';
import configs from '../config/config';
import {County} from "./County";

export class City extends Entity {
    id = 0;
    code = '';
    name = '';
    latitude = 0;
    longitude = 0;
    pk() {
        return `${this.id}`;
    }
}

// @ts-ignore
export const getCities = new RestEndpoint({
    urlPrefix: configs.apiUrl,
    path: '/county/:id',
    method: 'GET',
    schema: City,
});